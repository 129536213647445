import React from 'react'
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import {Link} from 'gatsby'
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import scrollTo from "gatsby-plugin-smoothscroll"



const HeaderWrapper = styled.div`
  padding: 30px 0;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;
  ul {
    li {
      margin-left: 30px;
      &:first-child {
        margin-left: 0;
      }
      a{
        font-weight: 600;
      }
    }
  }
  .get-touch {
    a,button {
      padding: 5px 25px;
      background-color: #bd9d3f;
      border-radius: 25px;
      border: 0;
      outline:0;
      color: #fff;
    }
  }
`

const Header = props => {

  const data = useStaticQuery(graphql`
    query image1 {
      file(relativePath: { eq: "codepros-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 50) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

 return (
   <HeaderWrapper className="header theme-ffffff">
     <Container>
       <Row className="align-items-center">
         <Col xs={2}>
           <a
             href="https://codepros.com.np"
             className="brand"
             target="_blank"
             rel="noreferrer"
           >
             <Img
               fluid={data.file.childImageSharp.fluid}
               alt="Associated with Code Pros"
             />
           </a>
         </Col>
         <Col xs={8} className="d-flex justify-content-center">
           <ul className="floated-list clearfix">
             <li>
               <Link to="/">Home</Link>
             </li>
             <li>
               <Link to="/works/">Works</Link>
             </li>
             <li>
               <Link to="/services/">Services</Link>
             </li>
           </ul>
         </Col>
         <Col xs={2} className="get-touch">
           <ul className="floated-list clearfix float-right">
             <li>
               <button onClick={() => scrollTo("#contactSection")}>Get in Touch</button>
             </li>
           </ul>
         </Col>
       </Row>
     </Container>
   </HeaderWrapper>
 )
}

export default Header;