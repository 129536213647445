import React from 'react'
import styled from 'styled-components'
import Row from 'react-bootstrap/Row'
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"

const FormWrapper = styled.section`
  background-color: #222222;
  .contact-form {
    margin-top: 40px;
    .row {
      div > div {
        margin-bottom: 30px;
        label {
          display: block;
          color: #aaa;
        }
        input,
        select,
        textarea {
          width: 100%;
          height: 50px;
          background-color: transparent;
          border: 2px solid #666;
          outline: 0;
          color: #fff;
          padding-left: 12px;
          padding-right: 12px;
        }
        textarea {
          height: 180px;
          padding-top: 12px;
        }
      }
    }
    button[type="submit"] {
      color: #fff;
      width: 145px;
      background-color: #bd9d3f;
      border: 0;
      padding: 5px 25px;
      border-radius: 25px;
      display: table;
      margin: 30px auto 0;
      outline:0;
    }
  }
  .return-msg{
    h2{
      margin-top: 30px;
      color: #fff;
      font-weight: 600;
      margin-bottom: 30px;
    }
    span{
      color: #bd9d3f;
    }
  }
`

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}


class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = { success: 0, failed: 0, name: "", email: "", company: "", address: "", howYoufoundMe: "", projectScope: "", projectDetails: "" };
  }

  /* Here’s the juicy bit for posting the form submission */

  handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...this.state })
    })
      .then(() => this.setState({ success: 1}))
      .catch(() => this.setState({ failed: 1}));

    e.preventDefault();
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    const { name, email, company, address, howYoufoundMe, projectScope, projectDetails } = this.state;

    return (
      <FormWrapper className="section" id="contactSection">
        <Container>
          <div className="section-info text-center">
            <div className="si-title">
              <h2>Get Started</h2>
              <span>Write me Up</span>
            </div>
          </div>
          {!this.state.failed && !this.state.success && (
            <Row className="justify-content-center cf-fields">
              <Col xs={8}>
                <form
                  name="contact"
                  method="POST"
                  data-netlify="true"
                  className="contact-form"
                  onSubmit={this.handleSubmit}
                >
                  <input type="hidden" name="form-name" value="contact" />
                  <Row>
                    <Col>
                      <div>
                        <label htmlFor="Your Full Name">Your Full Name:</label>
                        <input
                          type="text"
                          name="name"
                          value={name}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <label htmlFor="Your Email">Your Email:</label>
                        <input
                          type="email"
                          name="email"
                          value={email}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div>
                        <label htmlFor="Your Company Name">
                          Your Company Name:
                        </label>
                        <input
                          type="text"
                          name="company"
                          value={company}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <label htmlFor="Your Address">Your Address:</label>
                        <input
                          type="text"
                          name="address"
                          value={address}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div>
                        <label htmlFor="Where'd you Found me">
                          Where'd you Found me:
                        </label>
                        <select
                          name="howYouFoundMe"
                          value={howYoufoundMe}
                          onChange={this.handleChange}
                          required
                        >
                          <option value="google search">Google Search</option>
                          <option value="upwork">Upwork</option>
                          <option value="colleagues">Colleagues</option>
                          <option value="other">Other</option>
                        </select>
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <label htmlFor="Project Scope">Project Scope:</label>
                        <select
                          name="projectScope"
                          value={projectScope}
                          onChange={this.handleChange}
                          required
                        >
                          <option value="New Project">
                            Work in New Project
                          </option>
                          <option value="Existing Project">
                            Work in Existing Project
                          </option>
                          <option value="Idea">
                            Work in Idea based Project
                          </option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div>
                        <label htmlFor="Project Details">
                          Project Details:
                        </label>
                        <textarea
                          name="projectDetails"
                          value={projectDetails}
                          onChange={this.handleChange}
                          required
                        ></textarea>
                      </div>
                    </Col>
                  </Row>
                  <button type="submit">Send</button>
                </form>
              </Col>
            </Row>
          )}
          {this.state.success == 1 && (
            <Row className="justify-content-center return-msg">
              <Col xs={6}>
                <h2>Your Request is now Submitted</h2>
                <p>
                  Hi <span>{this.state.name}</span>, <br /> Thank You for
                  writing me, I will come back to you soon.
                </p>
              </Col>
            </Row>
          )}
          {this.state.failed == 1 && (
            <Row className="justify-content-center return-msg">
              <Col xs={6}>
                <h2>Sorry, Your request failed</h2>
                <p>
                  Hi <span>{this.state.name}</span>, <br /> Thank You for
                  writing me, but for some reason the submission failed, please
                  try in next few minutes.
                </p>
              </Col>
            </Row>
          )}
        </Container>
      </FormWrapper>
    )
  }
}

export default Form;