import React from 'react'
import styled from 'styled-components'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const FooterWrapper = styled.section`
  background-color: #010101;
  .f-col {
    color: #aaa;
    h3 {
      margin: 0 0 30px;
      color: #fff;
    }
    span {
      font-weight: 600;
      color: #bd9d3f;
    }
    .up-link {
      margin-top: 30px;
      color: #bd9d3f;;
    }
  }
  .copyright {
    margin-top: 50px;
    border-top: 1px solid #555;
    padding-top: 50px;
  }
`

const Footer = () => {

  const data = useStaticQuery(graphql`
    query image2 {
      file(relativePath: { eq: "codepros-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 50) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

 return (
   <FooterWrapper className="section">
     <Container>
       <Row>
         <Col xs={6}>
           <div className="f-col">
             <h3>About</h3>
             <p>
               Hello, I am Ankit Sigdel, from the beautiful country of
               'Himalayas', Nepal. I am a full time Front End Freelancer, with
               the extensive knowledge of Graphics Designing and Backend
               Development.
             </p>
             <a href="https://codepros.com.np" target="_blank" rel="noreferrer">
               <span>
                 Associated with Code Pros
                 <Img
                   fluid={data.file.childImageSharp.fluid}
                   alt="Associated with Code Pros"
                 />
               </span>
             </a>
           </div>
         </Col>
         <Col xs={2}></Col>
         <Col>
           <div className="f-col">
             <h3>Contact</h3>
             <ul>
               <li>Skype: factvsankit</li>
               <li>Phone: 9843368397</li>
               <li>
                 Email:
                 <a href="mailto:mta.ankit@gmail.com" rel="noreferrer">
                   mta.ankit@gmail.com
                 </a>
               </li>
               <li className="up-link">
                 <a href="https://www.upwork.com/freelancers/~01ec63770566abd9db" target="_blank" rel="noreferrer">
                   Upwork Profile
                 </a>
               </li>
             </ul>
           </div>
         </Col>
       </Row>
       <div className="copyright text-center">
         &copy; Ankit Sigdel, All Right Reserved.
       </div>
     </Container>
   </FooterWrapper>
 )
}

export default Footer