import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Container from 'react-bootstrap/Container'
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Img from "gatsby-image"
import styled from 'styled-components'


 const BannerWrapper = styled.div`
   height: 75vh;
   position: relative;
   &:after {
     position: absolute;
     left: 0;
     right: 0;
     bottom: 0;
     top: 0;
     content: "";
     background-color: rgba(0, 0, 0, 0.7);
     z-index: 1;
   }
   .gatsby-image-wrapper {
     height: inherit;
   }
   .banner-info {
     position: absolute;
     top: 0;
     right: 0;
     bottom: 0;
     left: 0;
     z-index: 2;
     h1 {
       border-bottom: 2px solid #bd9d3f;
       display: inline-block;
       padding-bottom: 3px;
       margin-bottom: 25px;
       font-size: 24px;
       color: #bd9d3f;
       margin-top: 30px;
       font-weight: 600;
     }
     p {
       font-weight: 600;
       color: #ccc;
       span {
         color: #bd9d3f;
       }
     }
   }
 `

const Banner = props => {

  const data = useStaticQuery(graphql`
    query image {
      file(relativePath: { eq: "home-banner.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <BannerWrapper>
      <Img fluid={data.file.childImageSharp.fluid} />
      <div className="d-flex banner-info align-items-center text-center">
        <Container>
          <h1 dangerouslySetInnerHTML={{ __html: props.title }}></h1>
          <Row className="justify-content-center">
            <Col xs={8}>
              <p dangerouslySetInnerHTML={{ __html: props.description }}></p>
            </Col>
          </Row>
        </Container>
      </div>
    </BannerWrapper>
  )
}

export default Banner
