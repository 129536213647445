import React, { Component } from 'react'
import Header from "./Header"
import Seo from "./Seo"

class Layout extends Component {

  constructor(props) {
    super(props)
    this.state = {
      sticky: false,
    }
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }
  handleScroll() {
    if (window.scrollY > 0) {
      this.setState({ sticky: true })
    } else {
      this.setState({ sticky: false })
    }
  }

  render() {
    return (
      <div className={`layout ${this.state.sticky ? "header-sticky" : ""}`}>
        <Seo></Seo>
        <Header></Header>
        {this.props.children}
      </div>
    )
  }
}



export default Layout

