import React from "react"
import { Helmet } from "react-helmet"

export default () => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>Ankit Sigdel || Web and Business Developer</title>
    <link rel="canonical" href="https://ankitsigdel.com" />
  </Helmet>
)
